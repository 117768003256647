<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i>
            </router-link>
          </li>
          <li>{{ this.$t('api_integration.api_integration') }}</li>
        </ul>
      </nav>
    </div>
    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span>{{ this.$t('api_integration.api_integration') }}</span>
        </h2>
      </div>
    </div>
    <template v-if="errorMessages">
      <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: red">
          <i class="uil-exclamation-triangle"></i> {{ error }}
        </p>
      </div>
    </template>
    <div v-if="successMessage" class="uk-alert-success" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: green">
        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
      </p>
    </div>

    <div class="chats-container margin-top-0">
      <div class="chats-container-inner">
        <!-- chats -->
        <div class="chats-inbox for_megaphone">
          <div class="chats-headline">
            <div class="input-with-icon">
              <input id="autocomplete-input" type="text" :placeholder="$t('general.search')" v-model="filter.search" />
              <i class="icon-material-outline-search"></i>
            </div>
          </div>
          <div style="padding:22px 30px">
            <div class="uk-grid-medium uk-slider-items uk-child-width-1-4@m uk-child-width-1-2@s pb-3 uk-grid">
              <div v-for="(integration, integrationsIndex) in integrations">
                <div class="card">
                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img class="w-75" src="/media/images/logo-anzera.jpg" />
                    </div>
                    <h5 class="h6 mt-4 mb-0">{{ integration.name }}</h5>
                    <span href="#" class="d-block text-sm text-success mb-3"><i class="uil-check-circle"></i>
                      {{ integration.activated_at ? 'active' : 'passive' }}</span>
                  </div>
                  <div class="card-footer text-center py-3 d-flex justify-content-between">
                    <label class="uk-switch uk-light">
                      <input type="checkbox" v-model="toggleActive[integrationsIndex]"
                        @change="toggleChange($event.target.checked, integrationsIndex)">
                      <div class="uk-switch-slider"></div>
                    </label>
                    <a href="#edit-integration" @click="openSettings(integration)" uk-toggle
                      class="uk-button uk-button-default uk-button-small"><i class="uil-cog"></i> Ayarlar</a>
                  </div>
                </div>
              </div>
              <!-- <div>
                <div class="card">
                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img class="w-75" src="/media/images/logo-kolayik.jpg" />
                    </div>
                    <h5 class="h6 mt-4 mb-0">Kolay İK</h5>
                    <span href="#" class="d-block text-sm text-muted mb-3">Passive</span>
                  </div>
                  <div class="card-footer text-center py-3 d-flex justify-content-between">
                    <label class="uk-switch uk-light" for="kolayik">
                      <input type="checkbox" id="kolayik">
                      <div class="uk-switch-slider"></div>
                    </label>
                    <a disabled href="#edit-integration" uk-toggle class="uk-button uk-button-default uk-button-small"><i
                        class="uil-cog"></i> Ayarlar</a>
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img class="w-75" src="/media/images/logo-sap.jpg" />
                    </div>
                    <h5 class="h6 mt-4 mb-0">SAP</h5>
                    <span href="#" class="d-block text-sm text-danger mb-3"><i class="uil-times-circle"></i> Failed</span>
                  </div>
                  <div class="card-footer text-center py-3 d-flex justify-content-between">
                    <label class="uk-switch uk-light" for="sap">
                      <input type="checkbox" id="sap" checked>
                      <div class="uk-switch-slider"></div>
                    </label>
                    <a href="#edit-integration" uk-toggle class="uk-button uk-button-default uk-button-small"><i
                        class="uil-cog"></i> Ayarlar</a>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- chats / End -->
      </div>
    </div>

    <!--Modal - Edit Integration-->
    <div id="edit-integration" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Entegrasyon Ayarları</h2>
        </div>
        <div class="uk-modal-body">
          <fieldset class="uk-fieldset">
            <div v-for="(field, index) in  fields ">
              <div v-if="'api_key' in field">
                <div class="uk-margin">
                  <label class="uk-form-label" for="api_key">{{ 'Api Key' }}<span class="required">*</span></label>
                  <input id="api_key" class="uk-input" type="text" v-model="fields[index]['api_key']"
                    placeholder="API Key Giriniz" />
                </div>
              </div>
              <div v-if="'x_key' in field">
                <div class="uk-margin">
                  <label class="uk-form-label" for="x_key">{{ 'X Key' }}<span class="required">*</span></label>
                  <input id="x_key" class="uk-input" type="text" v-model="fields[index]['x_key']"
                    placeholder="X Key Giriniz" />
                </div>
              </div>
              <div v-if="'secret_token' in field">
                <div class="uk-margin">
                  <label class="uk-form-label" for="secret_token">{{ 'Secret Token' }}<span
                      class="required">*</span></label>
                  <input id="secret_token" class="uk-input" type="text" v-model="fields[index]['secret_token']"
                    placeholder="Secret tOKEN Giriniz" />
                </div>
              </div>
              <div v-if="'client_id' in field">
                <div class="uk-margin">
                  <label class="uk-form-label">{{ 'Müşteri Id' }}<span class="required">*</span></label>
                  <input class="uk-input" type="text" v-model="fields[index]['client_id']"
                    placeholder="Müşteri Id Giriniz" />
                </div>
              </div>
              <div v-if="'account_id' in field">
                <div class="uk-margin">
                  <label class="uk-form-label">{{ 'Hesap Id' }}<span class="required">*</span></label>
                  <input class="uk-input" type="text" v-model="fields[index]['account_id']"
                    placeholder="Hesap Id Giriniz" />
                </div>
              </div>
              <div v-if="'client_secret' in field">
                <div class="uk-margin">
                  <label class="uk-form-label">{{ 'Müşteri Parolası' }}<span class="required">*</span></label>
                  <input class="uk-input" type="text" v-model="fields[index]['client_secret']"
                    placeholder="Müşteri Parolası Giriniz" />
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="uk-modal-footer">
          <div class="uk-margin">
            <button class="uk-button uk-button-default uk-modal-close mr-2" type="button">Vazgeç</button>
            <button type="submit" @click="saveForm" class="send-btn d-inline-block btn btn-default float-right">Kaydet
              <i class="uil-arrow-right"></i></button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import Pagination from "@/assets/components/Pagination";
import moment from "moment";

import module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  ITEMS,
  GET_ITEMS,
  DELETE_ITEM,
  CREATE_ITEM,
  UPDATE_ITEM
} from "@/core/services/store/integrations.module";

export default {
  name: "IntegrationsList",
  components: { Pagination },
  data() {
    return {
      toggleActive: [],
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 10,
      filter: {
        search: null,
        sort: "id",
        order: "desc",
      },
      integrations: [],
      fields: [],
      integrationId: ''
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    formattedItem() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      this.pagination = this.items;

      this.items.data.forEach((currentItem) => {
        results.push({
          id: currentItem.id,
          title: currentItem.name,
          description: currentItem.text ? currentItem.text : "-",
          status: currentItem.status_id
            ? this.$t("general.active")
            : this.$t("general.passive"),
          created_at: currentItem.created_at
            ? moment(moment(currentItem.created_at).format("YYYY-MM-DD HH:mm")).fromNow()
            : "-",
        });
      });
      return results;
    },
  },
  methods: {
    toggleChange(event, index) {
      this.integrationId = this.integrations[index].id;
      let sendData = {
        "is_active": event ? true : false
      };
      event ? this.integrations[index].activated_at = true : this.integrations[index].activated_at = false;
      store.dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
        url: 'api/company/' + this.integrationId + '/integrations',
        contents: sendData,
      }).then((response) => {
        if (!this.error) {
          this.successMessage = this.$t(response);
        } else this.errorMessages.push(this.error);
      });

      this.toggleActive[index] = event
    },
    openSettings(event) {
      this.fields = Object.entries(event.fields).map(entry => {
        return { [entry[0]]: entry[1] };
      });
      this.integrationId = event.id;
    },
    saveForm() {
      let sendData = {};
      let fieldValidate = true;
      this.fields.forEach((field, index) => {
        for (let i in field) {
          if (!field[i]) {
            fieldValidate = false;
            return false;
          }
          sendData[i] = field[i]
        }
      });
      if (!fieldValidate)
        return false;
      store.dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
        url: 'api/company/' + this.integrationId + '/integrations',
        contents: sendData,
      }).then((response) => {
        if (!this.error) {
          this.successMessage = this.$t("general.successfully_created");
          let fixedIntegrationId = this.integrations.findIndex((integration) => integration.id === this.integrationId);
          this.integrations[fixedIntegrationId].activated_at = true;
          this.toggleActive[fixedIntegrationId] = true;
          // this.integrations[]
          // this.$router.push({ name: "company.integrations.list" });
        } else this.errorMessages.push(this.error);

      });
      UIkit.modal("#edit-integration").hide();
      
    },
    getResult() {
      let self = this;
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        // filters: {
        //   like: self.filter.search,
        //   page: self.page,
        //   per_page: self.per_page,
        //   sort: self.filter.sort,
        //   order: self.filter.order,
        // },
      }).then((response) => {
        this.integrations = response;
        this.integrations.forEach((integration, index) => {
          if (integration.activated_at) {
            this.toggleActive[index] = true;
          } else {
            this.toggleActive[index] = false;
          }
        })
      }
      );
    },
    removeItem(id) {
      this.$store.dispatch(MODULE_NAME + "/" + DELETE_ITEM, {
        url: BASE_URL + "/" + id,
      }).then(() => {
        if (!this.error) {
          this.successMessage = this.$t("general.successfully_deleted");
          this.getResult()
        } else this.errorMessages.push(this.error)
      })
    },
    sortOrder(sort, order, is_user = true) {
      let self = this;
      self.page = 1;
      self.filter.sort = sort;
      self.filter.order = order;

      this.getResult();
    },
    setPage(page, is_user = true) {
      let self = this;
      self.page = page;
      this.getResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    this.getResult();
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>


<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-switch {
  height: 24px;
  width: 50px;
}

.uk-switch-slider:before {
  width: 20px;
  height: 20px;
}

.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
  background-color: #e6e6e6;
}
</style>
